// src/AppRoutes.js

import React, { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { initGA } from './utils/analytics';

// 🔹 Dina sidor
import BattlePage from './features/eloBattle/BattlePage';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import ProfilePage from './pages/ProfilePage';
import EloBattle from './features/eloBattle/EloBattle';
import LeaderboardAverage from './features/eloBattle/LeaderboardAverage';
import ImageEloHistoryPage from './features/eloBattle/ImageEloHistoryPage';
import RateImagePage from './features/rating/RateImagePage';
import RatingLeaderboard from './features/rating/RatingLeaderboard';
import WordInputPage from './features/wordcloud/WordInputPage';
import WordCloudPage from './features/wordcloud/WordCloudPage'; // Justera vid behov
import RatingChartPage from './features/rating/RatingChartPage';
import AddImage from './components/AddImage';
import ImageList from './components/ImageList';
import EditImage from './pages/EditImage';
import EditImageFile from './pages/EditImageFile';
import WordInputStaticImagePage from './features/wordcloud/WordInputStaticImagePage';
import ProtectedRoute from './auth/ProtectedRoute';

const AppRoutes = () => {
  const location = useLocation();

  useEffect(() => {
    initGA();
  }, []);

  useEffect(() => {
    if (typeof window.gtag === 'function') {
      window.gtag('event', 'page_view', {
        page_path: location.pathname + location.search,
        page_location: window.location.href,
        page_title: document.title,
      });
    }
  }, [location]);

  return (
    <Routes>
      <Route path="/" element={<BattlePage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/register" element={<RegisterPage />} />
      <Route path="/profile" element={<ProfilePage />} />
      <Route path="/elo/battle" element={<EloBattle />} />
      <Route path="/elo/leaderboard-average" element={<LeaderboardAverage />} />
      <Route path="/elo/history" element={<ImageEloHistoryPage />} />
      <Route path="/elo/history/:slug" element={<ImageEloHistoryPage />} />

      <Route path="/rate" element={<RateImagePage />} />
      <Route path="/ratings" element={<RatingLeaderboard />} />
      <Route path="/word-input" element={<WordInputPage />} />
      <Route path="/wordcloud" element={<WordCloudPage />} />
      <Route path="/wordcloud/:slug" element={<WordCloudPage />} />      <Route path="/ratings-chart" element={<RatingChartPage />} />
      <Route path="/add-image" element={<ProtectedRoute requiredRole="admin"><AddImage /></ProtectedRoute>} />
      <Route path="/list" element={<ProtectedRoute requiredRole="admin"><ImageList /></ProtectedRoute>} />
      <Route path="/edit/:id" element={<ProtectedRoute requiredRole="admin"><EditImage /></ProtectedRoute>} />
      <Route path="/edit-image-file" element={<ProtectedRoute requiredRole="admin"><EditImageFile /></ProtectedRoute>} />
      <Route path="/ord-static" element={<ProtectedRoute requiredRole="admin"><WordInputStaticImagePage /></ProtectedRoute>} />
    </Routes>
  );
};

export default AppRoutes;
