import React, { useEffect, useState, useRef } from 'react';
import { Container, Grid, Typography, Button, CircularProgress, Box } from '@mui/material';
import supabase from '../../utils/supabaseClient';

const RateImagePage = () => {
  const [imageData, setImageData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [imageLoaded, setImageLoaded] = useState(false);
  const firstLoad = useRef(true);

  useEffect(() => {
    if (firstLoad.current) {
      firstLoad.current = false;
      fetchNewImage();
    }
  }, []);

  const fetchNewImage = async () => {
    setLoading(true);
    setImageLoaded(false);

    try {
      const { data, error } = await supabase.rpc('get_image_to_rate');
      if (error || !data || data.length === 0) {
        console.error("❌ Kunde inte hämta bild att betygsätta:", error);
        setImageData(null);
      } else {
        setImageData(data[0]);
      }
    } catch (err) {
      console.error("❌ Ovväntat fel:", err);
    } finally {
      setLoading(false);
    }
  };

  const handleRating = async (rating) => {
    if (!imageData) return;

    try {
      const { error } = await supabase.rpc('insert_rating_with_metadata_secure', {
        p_image_id: imageData.id,
        p_rating: rating,
      });

      if (error) {
        console.error("❌ Kunde inte spara betyg:", error);
        return;
      }

      setTimeout(fetchNewImage, 100);
    } catch (error) {
      console.error("❌ Fel vid betygssättning:", error);
    }
  };

  return (
    <Container maxWidth="sm" sx={{ padding: 2 }}>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <Typography variant="h5" align="center">
            Betygsätt {imageData ? imageData.title : 'Bild'}
          </Typography>
        </Grid>

        {loading ? (
          <Grid item xs={12} display="flex" justifyContent="center">
            <CircularProgress />
          </Grid>
        ) : imageData ? (
          <>
            <Grid item xs={12} display="flex" justifyContent="center">
              <Box
                sx={{
                  width: "100%",
                  maxWidth: "640px",
                  aspectRatio: "4/3", // 🧠 Sätter förutsägbar layout
                  position: "relative",
                  borderRadius: 2,
                  overflow: "hidden",
                  backgroundColor: "#f0f0f0",
                }}
              >
                {!imageLoaded && (
                  <Box
                  sx={{
                    position: "absolute",
                    inset: 0,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#fff", // ✅ Vit bakgrund här
                    zIndex: 1,
                  }}
                >
                  <CircularProgress />
                </Box>
                )}
                <img
                  src={imageData.url}
                  alt={imageData.title}
                  onLoad={() => setImageLoaded(true)}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                    display: imageLoaded ? "block" : "none",
                  }}
                />
              </Box>
            </Grid>

            <Grid item xs={12} container justifyContent="center" spacing={1}>
              {[1, 2, 3, 4, 5].map((rating) => (
                <Grid item key={rating}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleRating(rating)}
                    size="large"
                  >
                    {rating}
                  </Button>
                </Grid>
              ))}
            </Grid>
          </>
        ) : (
          <Grid item xs={12}>
            <Typography variant="body1" align="center">
              Ingen ny bild tillgänglig.
            </Typography>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default RateImagePage;