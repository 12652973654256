// src/utils/analytics.js

export const initGA = (measurementId) => {
    if (!measurementId) return;
  
    // Förhindra att vi lägger till skriptet flera gånger
    if (window.gtagInitialized) return;
    window.gtagInitialized = true;
  
    const script1 = document.createElement('script');
    script1.async = true;
    script1.src = `https://www.googletagmanager.com/gtag/js?id=${measurementId}`;
    document.head.appendChild(script1);
  
    const script2 = document.createElement('script');
    script2.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${measurementId}');
    `;
    document.head.appendChild(script2);
  };

  