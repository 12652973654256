import React, { useState, useEffect } from 'react';
import { TextField, Button, Typography, Box, Grid } from '@mui/material';
import MultiSelect from '../components/MultiSelect';
import supabase from '../utils/supabaseClient';
import { useNavigate } from 'react-router-dom';

const AddImage = () => {
  const [file, setFile] = useState(null);
  const [title, setTitle] = useState('');
  const [isActive, setIsActive] = useState(true);
  const [businesses, setBusinesses] = useState([]);
  const [tags, setTags] = useState([]);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // Funktion för att generera titel baserat på filnamn
  const generateTitleFromFilename = (filename) => {
    if (!filename) return '';
    const nameWithoutExtension = filename.split('.').slice(0, -1).join('.');
    return nameWithoutExtension.charAt(0).toUpperCase() + nameWithoutExtension.slice(1);
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    if (selectedFile) {
      const generatedTitle = generateTitleFromFilename(selectedFile.name);
      setTitle(generatedTitle); // Sätt titeln baserat på filnamnet
    }
  };

  // Ladda förvalda värden från localStorage vid sidladdning
  useEffect(() => {
    const savedBusinesses = JSON.parse(localStorage.getItem('lastBusinesses')) || [];
    const savedTags = JSON.parse(localStorage.getItem('lastTags')) || [];
    setBusinesses(savedBusinesses);
    setTags(savedTags);
  }, []);

  const generateSlug = (filename) => {
    if (!filename) return '';
    const nameWithoutExtension = filename.split('.').slice(0, -1).join('.');
    return `${nameWithoutExtension.toLowerCase()}-logo`;
  };

  const handleSubmit = async () => {
    setLoading(true);

    try {
      if (!file) {
        setMessage('Ingen fil vald. Vänligen välj en bild.');
        setLoading(false);
        return;
      }

      // Kontrollera om bilden redan finns baserat på titel eller filnamn
      const { data: existingImages, error: existingError } = await supabase
  .from('images')
  .select('id')
  .or(`title.ilike.${title}, url.eq.${file.name}`);

      if (existingError) {
        console.error('Fel vid kontroll av befintliga bilder:', existingError);
        setMessage('Ett fel uppstod vid kontroll av befintliga bilder.');
        setLoading(false);
        return;
      }

      if (existingImages && existingImages.length > 0) {
        setMessage('En bild med samma titel eller filnamn finns redan.');
        setLoading(false);
        return;
      }

      // Ladda upp bilden till Supabase-bucket
      const filePath = `${file.name}`;
      const { data: uploadData, error: uploadError } = await supabase.storage
        .from('images') // Kontrollera att bucketen heter 'images'
        .upload(filePath, file);

      if (uploadError) {
        console.error('Error uploading image:', uploadError);
        setMessage('Ett fel uppstod vid uppladdning av bilden.');
        setLoading(false);
        return;
      }

      // Skapa den offentliga URL:en för bilden
      const publicURL = supabase.storage
        .from('images')
        .getPublicUrl(filePath).data.publicUrl;

      console.log('Image uploaded successfully. Public URL:', publicURL);

      // Spara metadata i databasen
      const { data, error: metadataError } = await supabase
        .from('images')
        .insert([
          {
            title: title,
            url: publicURL,
            is_active: isActive,
            slug: generateSlug(file.name), // 🆕 Skapa slug från filnamn

          },
        ])
        .select(); // Lägg till `select()` för att få tillbaka det sparade objektet

      if (metadataError) {
        console.error('Error saving image metadata:', metadataError);
        setMessage('Ett fel uppstod vid sparande av metadata.');
        setLoading(false);
        return;
      }

      if (!data || data.length === 0) {
        console.error('Metadata response is empty.');
        setMessage('Ett oväntat fel inträffade vid sparande av metadata.');
        setLoading(false);
        return;
      }

      const imageId = data[0].id;

      // Spara kopplingar för Business och Tags
      try {
        await Promise.all([
          ...businesses.map((businessId) =>
            supabase.from('image_business').insert({ image_id: imageId, business_id: businessId })
          ),
          ...tags.map((tagId) =>
            supabase.from('image_tags').insert({ image_id: imageId, tag_id: tagId })
          ),
        ]);
      } catch (error) {
        console.error('Error inserting into relationship tables:', error);
        setMessage('Ett fel uppstod vid sparande av affärsområden eller taggar.');
        setLoading(false);
        return;
      }

      // Spara de valda affärsområdena och taggarna i localStorage
      localStorage.setItem('lastBusinesses', JSON.stringify(businesses));
      localStorage.setItem('lastTags', JSON.stringify(tags));

      console.log('Image metadata and relationships saved successfully.');
      setMessage('Bilden har sparats och laddats upp.');
      setFile(null);
      setTitle('');
      setBusinesses(businesses); // Behåll tidigare valda värden
      setTags(tags); // Behåll tidigare valda värden
      setLoading(false);

      // Navigera tillbaka till listan
      navigate('/add-image');
    } catch (error) {
      console.error('Unexpected error during image upload:', error);
      setMessage('Ett oväntat fel inträffade.');
      setLoading(false);
    }
  };

  return (
    <Box sx={{ padding: 4 }}>
      <Typography variant="h4" gutterBottom>
        Ladda upp bild
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Button variant="contained" component="label">
            Välj bild
            <input type="file" hidden onChange={handleFileChange} />
          </Button>
          {file && <Typography mt={1}>Vald fil: {file.name}</Typography>}
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Titel"
            fullWidth
            variant="outlined"
            value={title}
            onChange={(e) => setTitle(e.target.value)} // Fältet är fortfarande redigerbart
          />
        </Grid>
        <Grid item xs={12}>
          <MultiSelect
            label="Välj Branscher"
            table="business"
            selectedValues={businesses}
            setSelectedValues={setBusinesses}
          />
        </Grid>
        <Grid item xs={12}>
          <MultiSelect
            label="Välj Taggar"
            table="tags"
            selectedValues={tags}
            setSelectedValues={setTags}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? 'Laddar upp...' : 'Ladda upp'}
          </Button>
        </Grid>
        {message && (
          <Grid item xs={12}>
            <Typography color={message.includes('sparats') ? 'primary' : 'error'} mt={2}>
              {message}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default AddImage;
