import { ThemeProvider } from '@mui/material/styles';
import theme from './theme/theme';
import { AuthProvider } from './auth/AuthProvider';
import { BrowserRouter as Router } from 'react-router-dom';
import AppBarWithDrawer from './components/layout/AppBarWithDrawer';
import Footer from './components/layout/Footer';
import AppRoutes from './AppRoutes';
import { useEffect } from 'react';
import { initGA } from './utils/analytics';
import { HelmetProvider } from 'react-helmet-async';

const App = () => {
  useEffect(() => {
    initGA();
  }, []);

  return (
    <HelmetProvider>
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <Router>
            <div
              className="App"
              style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}
            >
              <AppBarWithDrawer />
              <main style={{ flexGrow: 1, padding: 0 }}>
                <AppRoutes />
              </main>
              <Footer />
            </div>
          </Router>
        </AuthProvider>
      </ThemeProvider>
    </HelmetProvider>
  );
};

export default App;