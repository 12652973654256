import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import supabase from '../../utils/supabaseClient';
import {
  Container,
  Grid,
  Typography,
  CircularProgress,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@mui/material';
import EloHistoryChart from './EloHistoryChart';
import ImageTitleList from '../../components/shared/ImageTitleList';
import { Helmet } from 'react-helmet-async';

function ImageEloHistoryPage() {
  const { slug } = useParams();
  const navigate = useNavigate();

  const [images, setImages] = useState([]);
  const [selectedSlug, setSelectedSlug] = useState(slug || '');
  const [imageData, setImageData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchImages() {
      setLoading(true);
      const { data, error } = await supabase
        .from('images')
        .select('id, title, slug')
        .order('title', { ascending: true });

      if (error) {
        console.error('Fel vid hämtning av bilder:', error);
      } else {
        setImages(data);
      }
      setLoading(false);
    }

    fetchImages();
  }, []);

  useEffect(() => {
    const fetchEloHistory = async () => {
      if (!slug) {
        setImageData(null);
        return;
      }

      setLoading(true);

      const { data: image, error: imageError } = await supabase
        .from('images')
        .select('id, title')
        .eq('slug', slug)
        .single();

      if (imageError || !image) {
        console.error('Fel vid hämtning av bildmetadata:', imageError);
        setImageData(null);
        setLoading(false);
        return;
      }

      const { data: eloData, error: eloError } = await supabase
        .from('elo_history_for_graph_v2')
        .select('elo_score, created_at')
        .eq('image_id', image.id)
        .order('created_at', { ascending: true });

      if (eloError) {
        console.error('Fel vid hämtning av ELO-data:', eloError);
        setImageData(null);
      } else {
        setImageData({ id: image.id, title: image.title, eloHistory: eloData });
      }

      setLoading(false);
    };

    fetchEloHistory();
  }, [slug]);

  useEffect(() => {
    setSelectedSlug(slug || '');
  }, [slug]);

  const generateChartData = () => {
    if (!imageData || imageData.eloHistory.length === 0) return null;

    const labels = imageData.eloHistory.map((entry) =>
      new Date(entry.created_at).toLocaleDateString('sv-SE', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      })
    );

    const data = imageData.eloHistory.map((entry) => entry.elo_score);

    return {
      labels,
      datasets: [
        {
          label: 'ELO Poäng',
          data,
          borderColor: 'rgba(75, 192, 192, 1)',
          backgroundColor: 'rgba(75, 192, 192, 0.2)',
          tension: 0,
          pointRadius: 4,
          pointHoverRadius: 6,
          borderWidth: 2,
          spanGaps: true,
        },
      ],
    };
  };

  return (
    <>
     <Helmet>
  <title>
    {imageData?.title
      ? `ELO-historik för ${imageData.title} – Se popularitetsutveckling`
      : 'ELO-graf över Sveriges populäraste varumärken – Brand-o-meter'}
  </title>
  <meta
    name="description"
    content={
      imageData?.title
        ? `Se hur ${imageData.title} har utvecklats i popularitet över tid. ELO-baserad historik från riktiga användarbattles – visualiserat som graf.`
        : 'Upptäck hur populära svenska varumärken har utvecklats över tid. Visualisering av ELO-historik baserad på tusentals dueller.'
    }
  />
  <meta
    property="og:title"
    content={
      imageData?.title
        ? `ELO-graf för ${imageData.title}`
        : 'Graf över varumärkens ELO-historik'
    }
  />
  <meta
    property="og:description"
    content="Se hur svenska varumärken presterat i popularitetsdueller över tid. Interaktiv grafik från Brand-o-meter."
  />
  {imageData?.id && (
    <meta
      property="og:image"
      content={`https://brand-o-meter.se/api/screenshot/elo/${imageData.id}`} // eller byt till en statisk fallback
    />
  )}
  <meta property="og:type" content="website" />
</Helmet>

      <Container maxWidth="lg" sx={{ mt: 4 }}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12}>
            <Typography variant="h4" align="center">
              {imageData?.title
                ? `ELO-historik för ${imageData.title}`
                : 'Välj ett varumärke'}
            </Typography>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel>Välj ett varumärke</InputLabel>
              <Select
                value={images.some((img) => img.slug === selectedSlug) ? selectedSlug : ''}
                onChange={(e) => {
                  setSelectedSlug(e.target.value);
                  navigate(`/elo/history/${e.target.value}`);
                }}
              >
                {images.map((image) => (
                  <MenuItem key={image.id} value={image.slug}>
                    {image.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {loading && (
            <Grid item xs={12} align="center">
              <CircularProgress />
            </Grid>
          )}

          {imageData && (
            <Grid item xs={12}>
              <EloHistoryChart data={generateChartData()} />
            </Grid>
          )}
        </Grid>
      </Container>

      <ImageTitleList
  images={images}
  onSelect={(slug) => navigate(`/elo/history/${slug}`)}
/>
    </>
  );
}

export default ImageEloHistoryPage;
