import { useState, useEffect, useRef } from 'react';
import { Grid, Container, Typography, Box, CircularProgress } from '@mui/material';
import supabase from '../../utils/supabaseClient';

const EloBattle = () => {
  const [images, setImages] = useState([]);
  const [newImages, setNewImages] = useState(null); // Temporära bilder innan byte
  const [loading, setLoading] = useState(false);
  const [lastSelected, setLastSelected] = useState(null);
  const imageContainerRef = useRef(null); // 🔹 Sparar föregående höjd på bildcontainern
  const [imageLoaded, setImageLoaded] = useState(false);
  const firstLoad = useRef(true); // Hindra dubbelanrop

  useEffect(() => {
    if (firstLoad.current) {
      firstLoad.current = false;
      fetchNewImages();
    }
  }, []);

  const fetchNewImages = async () => {
    console.log('🔄 Hämtar nya bilder...');
    setLoading(true);

    try {
      let images = [];
      const { data, error } = await supabase.rpc('select_images');
      if (error) throw error;

      if (data.length > 0) {
        const randomRow = data[Math.floor(Math.random() * data.length)];

        images = [
          {
            image_id: randomRow.image1_id,
            url: randomRow.image1_url,
            title: randomRow.image1_title,
            current_elo: randomRow.image1_elo
          },
          {
            image_id: randomRow.image2_id,
            url: randomRow.image2_url,
            title: randomRow.image2_title,
            current_elo: randomRow.image2_elo
          }
        ];
      }

      if (images.length === 2) {
        console.log('✅ Nya bilder hämtade:', images);
        setNewImages(images); // 🔹 Vänta på att byta bilder tills de laddats
      } else {
        console.error('❌ Kunde inte hämta två bilder.');
      }
    } catch (error) {
      console.error('❌ Fel vid hämtning av bilder:', error.message);
    }
  };

  // 🔹 Vänta på att bilderna laddas innan vi byter ut dem
  useEffect(() => {
    if (!newImages) return;

    console.log('📸 Laddar nya bilder i bakgrunden...');
    const img1 = new Image();
    const img2 = new Image();
    img1.src = newImages[0].url;
    img2.src = newImages[1].url;

    img1.onload = img2.onload = () => {
      console.log('✅ Nya bilder laddade.');
      if (imageContainerRef.current) {
        imageContainerRef.current.style.minHeight = `${imageContainerRef.current.offsetHeight}px`;
      }
      setImages(newImages); // ✅ Byt bilder när de är redo
      setLoading(false);
    };
  }, [newImages]);

  

  const handleVote = async (winnerId, loserId) => {
    console.log(`📊 Röstning pågår: Winner - ${winnerId}, Loser - ${loserId}`);
    setLoading(true);
  
    try {
      const { data, error } = await supabase.rpc('update_elo_secure', {
        winner_id: winnerId,
        loser_id: loserId,
      });
  
      console.log('🔍 Debug: Response från Supabase:', data);
  
      if (error) {
        console.error('❌ Fel vid uppdatering av ELO:', error);
        return;
      }
  
      if (!data || data.length === 0) {
        console.error('❌ Ingen data returnerad från backend');
        return;
      }
  
      const { winner_title, winrate } = data[0];
  
      console.log(`✅ Du röstade på ${winner_title} som har ${winrate ?? 0}% winrate`);
      setLastSelected({
        name: winner_title || 'Okänd',
        winRate: (winrate ?? 0).toFixed(2),
      });
  
      await fetchNewImages(); // 🔁 Nästa battle
    } catch (error) {
      console.error('❌ Fel vid hantering av röstning:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="lg" sx={{ textAlign: 'center', mt: 4 }}>
      {/* Titel */}
      <Typography variant="h4" gutterBottom>
        {images.length === 2 ? `${images[0].title} vs ${images[1].title}` : 'ELO Battle'}
      </Typography>

      {/* 🔹 Bildcontainern behåller sin föregående höjd */}
      <Grid container spacing={3} justifyContent="center" alignItems="center" ref={imageContainerRef}>
        {images.map((image) => (
          <Grid item xs={12} sm={6} md={5} key={image.image_id}>
            <Box
  sx={{
    width: "100%",
    aspectRatio: "4 / 3",
    backgroundColor: "#f0f0f0",
    position: "relative",
    borderRadius: 2,
    overflow: "hidden",
    cursor: "pointer",
  }}
  onClick={() =>
    handleVote(
      image.image_id,
      images.find((img) => img.image_id !== image.image_id).image_id
    )
  }
>
  {!imageLoaded && (
    <Box
      sx={{
        position: "absolute",
        inset: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#fff",
        zIndex: 1,
      }}
    >
      <CircularProgress />
    </Box>
  )}
  <img
    src={image.url}
    alt={image.title}
    onLoad={() => setImageLoaded(true)}
    style={{
      width: "100%",
      height: "100%",
      objectFit: "contain",
      display: imageLoaded ? "block" : "none",
    }}
  />
</Box>
          </Grid>
        ))}
      </Grid>

      {/* 🔹 Visa senaste matchens resultat */}
      {lastSelected && (
        <Grid container justifyContent="center" sx={{ mt: 3 }}>
          <Grid item xs={12} md={8}>
            <Typography variant="h6" align="center" sx={{ fontWeight: 'bold' }}>
              Du valde {lastSelected.name} i senaste matchen
            </Typography>
            <Typography align="center" color="#666">
              {lastSelected.name} har vunnit {lastSelected.winRate}% av sina matcher.
            </Typography>
          </Grid>
        </Grid>
      )}

      {/* 🔹 Separat div för texten */}
      <Grid container justifyContent="center" sx={{ mt: 3 }}>
        <Grid item xs={12} md={8}>
          <Typography color="#999" align="center" variant="body1" sx={{ maxWidth: '800px', mx: 'auto' }}>
            Klicka på det varumärke du gillar bäst! Om du inte känner till dem, eller de är svåra att jämföra, klicka på den logotyp som ger dig bäst känsla.
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default EloBattle;